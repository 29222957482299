<template>
  <div class="banner-wrap">
    <div class="wrapper">
      <el-carousel indicator-position="outside" :interval="3000" arrow="always" :autoplay="true">
        <el-carousel-item v-for="item in list" :key="item">
          <h3 class="scrolling-text">{{ item }}</h3>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
// swiper options example:
import { scrollingText } from '@/api/home'
export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    scrollingText().then(res => {
      this.list = res
    })
  }
}
</script>
<style scoped lang="scss">
.banner-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 49px;
  // overflow: hidden;
  position: relative;
}
.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.scrolling-text {
  text-align: center;
  line-height: 49px;
  font-size: 15px;
}
/deep/.el-carousel__arrow:hover{
  background: none;
  color: #ed800a;
}
/deep/.el-carousel__arrow {
  color:#000;
  background: none;
  font-size: 22px;
}
/deep/.el-carousel__indicators--outside {
  display: none;
}
@media screen and (max-width: 748px) {
  /deep/.el-carousel {
    width: 600px;
  }
}
</style>
